import Vue from 'vue'
import App from './App.vue'
import router from './router';
import "./assets/css/reset.css"
import "./assets/css/common.css"
import "./assets/css/bannerVideo.css"
import "./assets/css/layout.css"
import "./assets/css/swiper.3.4.2.min.css"
import "./assets/css/animate.min.css"
import "./assets/css/index.css"
import "./assets/css/others.css"
Vue.config.productionTip = false

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
