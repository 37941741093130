<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'App',
		provide() {
			return {
				app: this
			}
		},
		data() {
			return {
				isMobile: false // 设备类型标志
			}
		},
		mounted() {
			// 在组件挂载时检测当前屏幕大小
			this.checkDeviceType();

			// 监听窗口 resize 事件
			window.addEventListener('resize', this.checkDeviceType);
		},
		beforeDestroy() {
			// 组件销毁前移除事件监听器
			window.removeEventListener('resize', this.checkDeviceType);
		},
		methods: {
			// 根据窗口宽度判断设备类型
			checkDeviceType() {
				// 设置一个阈值来判断是移动设备还是PC
				const mobileBreakpoint = 1000;
				this.isMobile = window.innerWidth <= mobileBreakpoint;
				console.log(window.innerWidth)
			}
		}
	};
</script>

<style>
</style>